import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FormationService } from "src/app/services/formation.service";
import { SheetContent } from "src/app/models/sheet-content.class";

@Component({
  selector: "app-add-formation",
  templateUrl: "./add-formation.component.html",
  styleUrls: ["./add-formation.component.scss"],
})
export class AddFormationComponent implements OnInit {
  @Input() formations: SheetContent;
  @Input() rateCodes: SheetContent;
  @Input() projectId: string;
  @Output() closeModalEmitter = new EventEmitter<boolean>();
  formationName: string;
  formationDescription: string;
  rateCodeSelected: string;
  rateCodeList: Array<string> = [];
  formationVersion: number;
  nbFormation: number;
  codeFormation: string;
  formationDuration: number;

  constructor(private formationService: FormationService) {}

  ngOnInit(): void {
    this.nbFormation = 1;
    this.formationVersion = 2018;
    this.formationDuration = 1;
    this.getRateCodeList();
  }

  createNewFormation() {
    this.formationService.createNewFormation(
      this.projectId,
      this.formations,
      this.formationName,
      this.formationDescription,
      this.rateCodeSelected,
      this.formationVersion,
      this.codeFormation,
      this.formationDuration,
      () => {
        if (this.rateCodeList.indexOf(this.rateCodeSelected) === -1) {
          this.rateCodeList.push(this.rateCodeSelected);
        }
        this.addNewRateCodes();
        this.closeModalEmitter.emit(true);
      }
    );
  }

  // RateCode saved in the formations (sheet Fiches de formations)
  getRateCodeList() {
    this.formations.values.forEach((formation) => {
      if (this.rateCodeList.indexOf(formation[1]) === -1) {
        this.rateCodeList.push(formation[1]);
      }
    });
    this.rateCodes.values.forEach((rateCode) => {
      if (this.rateCodeList.indexOf(rateCode[1]) === -1) {
        this.rateCodeList.push(rateCode[1]);
      }
    });
  }

  addNewRateCodes() {
    this.formationService.saveRateCodes(
      this.projectId,
      this.rateCodeList.map((value) => [value])
    );
  }

  closeModal() {
    this.closeModalEmitter.emit(true);
  }
}
