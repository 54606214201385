import { FormsModule } from "@angular/forms";
import { UserService } from "./../services/user.service";
import { HeaderComponent } from "./header/header.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

@NgModule({
  declarations: [HeaderComponent],
  imports: [CommonModule, FormsModule],
  exports: [HeaderComponent, CommonModule, FormsModule]
})
export class SharedModule {}
