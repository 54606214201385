<div class="container">
  <div class="logo-container">
    <img class="logo-home" src="assets/img/sheets-2.png" />
  </div>
  <div class="login-container">
    <div class="login-card">
      <div class="title-container">
        <h1>Se connecter</h1>
      </div>
      <p>Choisir un rôle pour se connecter à l'application</p>
      <div class="button-container">
        <div>
          <button class="button big accent" (click)="signIn('formateur')">
            Formateur
          </button>
          <button class="button big accent" (click)="signIn('responsable')">
            Responsable
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
