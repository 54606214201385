import { UserService } from "./../services/user.service";
import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { GoogleFile } from '../models/google-file.class';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  constructor(private userService: UserService, private router: Router) {}

  ngOnInit(): void {
    if (this.userService.isUserSignedIn()) {
      const selectedFolder = JSON.parse(sessionStorage.getItem('selectedFolder')) as GoogleFile;
      if (selectedFolder == null) {
        this.router.navigate(['dossiers']);
      } else {
        this.router.navigate(['dossiers', selectedFolder.id, 'projets']);
      }
    }
  }

  public signIn(role: string) {
    this.userService.signIn(role);
  }

  public signOut() {
    this.userService.signOut();
  }

  public getRole(): string {
    return this.userService.getRole();
  }

  public isLoggedIn(): boolean {
    return this.userService.isUserSignedIn();
  }
}
