<app-header
  [containerTitle]="formation[3] ? formation[3] : 'Pas de nom'"
></app-header>
<div class="main-container">
  <div class="content">
    <button class="button back float rounded secondary" (click)="back()">
      <img class="back-icon" src="assets/img/left-arrow.png" />
    </button>
    <div class="formation-info">
      <div class="infos">
        <div class="label">
          Code tarifaire :
          <span class="info" *ngIf="!editMode">{{ formation[1] }}</span
          ><input *ngIf="editMode" [(ngModel)]="formation[1]" />
        </div>
        <div class="label">
          Version :
          <span class="info" *ngIf="!editMode">{{ formation[0] }}</span
          ><input *ngIf="editMode" [(ngModel)]="formation[0]" />
        </div>
      </div>
      <div class="button-container">
        <button
          class="button accent icon-container"
          *ngIf="canAdd && !editMode"
          (click)="toggleEdit()"
        >
          <img class="icon" src="assets/img/edit.png" />
        </button>
        <button
          class="button medium lightgray"
          *ngIf="editMode"
          (click)="toggleEdit()"
        >
          Annuler</button
        ><button
          class="button medium accent"
          *ngIf="editMode"
          (click)="updateFormations()"
        >
          Valider
        </button>
        <button
          *ngIf="!editMode"
          class="button accent icon-container"
          (click)="downloadFormation()"
        >
          <img class="icon" src="assets/img/download.png" />
        </button>
        <button
          *ngIf="!editMode"
          class="button accent icon-container"
          (click)="openSendModal()"
        >
          <img class="icon" src="assets/img/mail.png" />
        </button>
      </div>
    </div>
    <hr />
    <div #elementList class="list">
      <app-element
        *ngFor="
          let element of elementsSheetContent.values
            | elementFormation: formation[2];
          let i = index
        "
        [element]="element"
        (update)="updateElements($event)"
        (delete)="deleteElement(element)"
        [canEditAndDelete]="canAdd"
      >
      </app-element>
      <div class="button-add-center">
        <button
          class="button rounded accent add-formation-button"
          *ngIf="canAdd"
          (click)="openAddModal()"
        >
          <img class="back-icon" src="assets/img/plus.png" />
        </button>
      </div>
    </div>
    <app-add-element
      *ngIf="showModal"
      [elementsSheetContent]="elementsSheetContent"
      (closeModalEmitter)="hideModal($event)"
    ></app-add-element>
    <app-send-element
      *ngIf="showSendModal"
      [elementsSheetContent]="elementsSheetContent"
      [formation]="formation"
      [formationId]="formationId"
      [projectFile]="projectFile"
      (closeModalEmitter)="hideSendModal($event)"
    ></app-send-element>
  </div>
</div>
