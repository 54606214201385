import { Injectable } from "@angular/core";
import { GoogleDriveService } from "./google-drive.service";
import { GoogleSpreadsheetsService } from "./google-spreadsheets.service";
import { SheetContent } from "../models/sheet-content.class";

@Injectable({
  providedIn: "root",
})
export class FormationService {
  constructor(private googleSpreadsheetsService: GoogleSpreadsheetsService) {}

  saveRateCodes(projectId: string, rateCodes: Array<Array<string>>) {
    if (rateCodes.length === 0) {
      return;
    }
    const rateCodesSheetContent = new SheetContent();
    rateCodesSheetContent.values = rateCodes;
    rateCodesSheetContent.majorDimension = "ROWS";
    rateCodesSheetContent.range = "Codes Tarifaires!B2:B100";
    this.googleSpreadsheetsService
      .put(projectId, "Codes Tarifaires", "B2", "B100", rateCodesSheetContent)
      .subscribe(() => {});
  }

  createNewFormation(
    projectId: string,
    formationsContent: SheetContent,
    formationName: string,
    formationDescription: string,
    rateCode: string,
    formationVersion: number,
    codeFormation: string,
    formationDuration: number,
    callback: any
  ) {
    let nbFormation = 1;

    formationsContent.values.forEach((formation) => {
      if (formation[1] === rateCode) {
        nbFormation++;
      }
    });

    // Formater le code de formation pour garder une cohérence avec les codes de formations déjà existant
    codeFormation = rateCode + "-" + ("0" + nbFormation).slice(-2);

    const newFormation = new SheetContent();
    newFormation.range =
      "Fiches de formations!" + `A${formationsContent.values.length + 2}:Z100`;
    newFormation.majorDimension = "ROWS";
    newFormation.values = [
      [
        String(formationVersion),
        rateCode,
        codeFormation,
        formationName,
        formationDescription,
        String(formationDuration),
      ],
    ];
    this.googleSpreadsheetsService
      .put(
        projectId,
        "Fiches de formations",
        `A${formationsContent.values.length + 2}`,
        "Z100",
        newFormation
      )
      .subscribe(callback);
  }

  deleteFormations(projectId, callback) {
    const emptySheetContent = new SheetContent();
    emptySheetContent.range = "Fiches de formations!A2:Z100";
    emptySheetContent.majorDimension = "ROWS";
    for (let index = 0; index < 99; index++) {
      // 99 because Z100
      emptySheetContent.values[index] = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];
    }
    this.googleSpreadsheetsService
      .put(
        projectId,
        "Fiches de formations",
        "A2",
        "Z100", // here
        emptySheetContent
      )
      .subscribe(callback);
  }

  overwriteFormations(projectId: string, formations: SheetContent, callback) {
    formations.range = "Fiches de formations!A2:Z100";
    this.googleSpreadsheetsService
      .put(projectId, "Fiches de formations", `A2`, "Z100", formations)
      .subscribe(callback);
  }
}
