<div class="container">
  <span>
    <span [routerLink]="'formations/' + formation[2]" class="bold title">{{
      formation[3] ? formation[3] : "Pas de nom"
    }}</span
    ><br />
    <div class="text">
      {{ formation[4] ? formation[4] : "Pas de description" }}
    </div></span
  >
  <span
    ><button
      *ngIf="canDelete"
      class="button red rounded icon-container"
      (click)="deleteFormation()"
    >
      <div class="in-icon-container">
        <img class="icon" src="assets/img/close.png" />
      </div></button
  ></span>
</div>
