export const environment = {
  production: false,
  templateSpreadsheetCreation: {
    properties: {
      title: "",
      locale: "fr_FR",
      autoRecalc: "ON_CHANGE",
      timeZone: "Europe/Berlin",
      defaultFormat: {
        backgroundColor: {
          red: 1,
          green: 1,
          blue: 1,
        },
        padding: {
          top: 2,
          right: 3,
          bottom: 2,
          left: 3,
        },
        verticalAlignment: "BOTTOM",
        wrapStrategy: "OVERFLOW_CELL",
        textFormat: {
          foregroundColor: {},
          fontFamily: "arial,sans,sans-serif",
          fontSize: 10,
          bold: false,
          italic: false,
          strikethrough: false,
          underline: false,
          foregroundColorStyle: {
            rgbColor: {},
          },
        },
        backgroundColorStyle: {
          rgbColor: {
            red: 1,
            green: 1,
            blue: 1,
          },
        },
      },
    },
    sheets: [
      {
        properties: {
          title: "Meta-Donnees",
          index: 0,
          sheetType: "GRID",
          gridProperties: {
            rowCount: 1000,
            columnCount: 26,
          },
        },
        data: {
          startRow: 0,
          startColumn: 0,
          rowMetadata: [{}],
          columnMetadata: [{}],
          rowData: [
            {
              values: [
                {
                  userEnteredValue: { stringValue: "Version Applicatif" },
                },
                {
                  userEnteredValue: { stringValue: "Nom Projet" },
                },
                {
                  userEnteredValue: { stringValue: "Description Projet" },
                },
              ],
            },
            {
              values: [
                {
                  userEnteredValue: { stringValue: "1.0" },
                },
                {
                  userEnteredValue: { stringValue: "" },
                },
                {
                  userEnteredValue: { stringValue: "" },
                },
              ],
            },
          ],
        },
      },
      {
        properties: {
          title: "Codes tarifaires",
          index: 1,
          sheetType: "GRID",
          gridProperties: {
            rowCount: 955,
            columnCount: 23,
            frozenRowCount: 1,
          },
        },
        data: {
          startRow: 0,
          startColumn: 0,
          rowMetadata: [{}],
          columnMetadata: [{}],
          rowData: [
            {
              values: [
                {
                  userEnteredValue: { stringValue: "Version" },
                },
                {
                  userEnteredValue: { stringValue: "Code Tarifaire" },
                },
                {
                  userEnteredValue: { stringValue: "Libelle Code Tarifaire" },
                },
                {
                  userEnteredValue: {
                    stringValue: "Description Code Tarifaire",
                  },
                },
              ],
            },
          ],
        },
      },
      {
        properties: {
          title: "Fiches de formations",
          index: 2,
          sheetType: "GRID",
          gridProperties: {
            rowCount: 1802,
            columnCount: 24,
            frozenRowCount: 1,
          },
        },
        data: {
          startRow: 0,
          startColumn: 0,
          rowMetadata: [{}],
          columnMetadata: [{}],
          rowData: [
            {
              values: [
                {
                  userEnteredValue: { stringValue: "Version" },
                },
                {
                  userEnteredValue: { stringValue: "Code tarifaire" },
                },
                {
                  userEnteredValue: {
                    stringValue: "Code de fiche de formation",
                  },
                },
                {
                  userEnteredValue: {
                    stringValue: "Libelle de fiche de formation",
                  },
                },
                {
                  userEnteredValue: {
                    stringValue: "Description de fiche de formation",
                  },
                },
                {
                  userEnteredValue: {
                    stringValue: "Duree	Deroulement / plan de travail",
                  },
                },
                {
                  userEnteredValue: { stringValue: "Formateur referent" },
                },
                {
                  userEnteredValue: { stringValue: "Stagiaire referent" },
                },
                {
                  userEnteredValue: { stringValue: "Societe(s) formee(s)" },
                },
                {
                  userEnteredValue: { stringValue: "Dates de formation" },
                },
                {
                  userEnteredValue: {
                    stringValue: "Remarques ou demandes (des referents)",
                  },
                },
              ],
            },
          ],
        },
      },
      {
        properties: {
          title: "Elements des fiches de formation",
          index: 3,
          sheetType: "GRID",
          gridProperties: {
            rowCount: 1481,
            columnCount: 14,
            frozenRowCount: 1,
          },
        },
        data: {
          startRow: 0,
          startColumn: 0,
          rowMetadata: [{}],
          columnMetadata: [{}],
          rowData: [
            {
              values: [
                {
                  userEnteredValue: { stringValue: "Version" },
                },
                {
                  userEnteredValue: { stringValue: "Code tarifaire" },
                },
                {
                  userEnteredValue: {
                    stringValue: "Code de fiche de formation",
                  },
                },
                {
                  userEnteredValue: { stringValue: "Categorie d'element" },
                },
                {
                  userEnteredValue: {
                    stringValue: "Numero sequentiel",
                  },
                },
                {
                  userEnteredValue: {
                    stringValue: "Description de l'element",
                  },
                },
                {
                  userEnteredValue: { stringValue: "Etat de l'element" },
                },
              ],
            },
          ],
        },
      },
      {
        properties: {
          title: "Taches du projet",
          index: 4,
          sheetType: "GRID",
          gridProperties: {
            rowCount: 1000,
            columnCount: 26,
          },
        },
        data: {
          startRow: 0,
          startColumn: 0,
          rowMetadata: [{}],
          columnMetadata: [{}],
          rowData: [
            {
              values: [
                {
                  userEnteredValue: { stringValue: "Type Action" },
                },
                {
                  userEnteredValue: { stringValue: "Ordre Projet" },
                },
                {
                  userEnteredValue: { stringValue: "Code Tarifaire" },
                },
                {
                  userEnteredValue: { stringValue: "Sous Code Tarifaire" },
                },
                {
                  userEnteredValue: { stringValue: "Famille d'action" },
                },
                {
                  userEnteredValue: { stringValue: "Action" },
                },
                {
                  userEnteredValue: { stringValue: "Phase" },
                },
                {
                  userEnteredValue: { stringValue: "Temps prévu" },
                },
                {
                  userEnteredValue: { stringValue: "Temps minimum estimé" },
                },
                {
                  userEnteredValue: { stringValue: "Type de facturation" },
                },
                {
                  userEnteredValue: { stringValue: "Coût unitaire" },
                },
                {
                  userEnteredValue: {
                    stringValue: "Charge minimum maîtrise ouvrage",
                  },
                },
                {
                  userEnteredValue: { stringValue: "Apparant sur planning" },
                },
                {
                  userEnteredValue: { stringValue: "Date début" },
                },
                {
                  userEnteredValue: { stringValue: "Date fin" },
                },
                {
                  userEnteredValue: { stringValue: "Référent Maitre oeuvre" },
                },
                {
                  userEnteredValue: { stringValue: "Référent Maitre ouvrage" },
                },
                {
                  userEnteredValue: { stringValue: "Liste ressources" },
                },
                {
                  userEnteredValue: { stringValue: "Description" },
                },
              ],
            },
          ],
        },
      },
    ],
  },
  availableStates: {
    T: "Terminé",
    EC: "En cours",
    AF: "A faire",
    NC: "Non concerné",
  },
};
