import { ElementsModule } from "./elements/elements.module";
import { FormationsModule } from "./formations/formations.module";
import { LoginModule } from "./login/login.module";
import { ProjectsModule } from "./projects/projects.module";
import { SharedModule } from "./shared/shared.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { GoogleApiModule, NgGapiClientConfig, NG_GAPI_CONFIG } from "ng-gapi";

import { AppComponent } from "./app.component";
import { FourOOneInterceptor } from "./four-o-one.interceptor";
import { FoldersModule } from "./folders/folders.module";

export let gapiClientConfig: NgGapiClientConfig = {
  client_id:
    "223515682308-jvsu38or9lj624r47v4vgooe8ebtfjds.apps.googleusercontent.com",
  discoveryDocs: [
    "https://sheets.googleapis.com/$discovery/rest?version=v4",
    "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
    "https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest",
  ],
  scope: [
    "https://www.googleapis.com/auth/spreadsheets",
    "https://www.googleapis.com/auth/drive",
    "https://mail.google.com/",
    "https://www.googleapis.com/auth/gmail.compose",
    "https://www.googleapis.com/auth/gmail.send",
    "https://www.googleapis.com/auth/gmail.modify",
    "https://www.googleapis.com/auth/gmail.addons.current.action.compose ",
  ].join(" "),
};
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    GoogleApiModule.forRoot({
      provide: NG_GAPI_CONFIG,
      useValue: gapiClientConfig,
    }),
    SharedModule,
    ProjectsModule,
    LoginModule,
    FormationsModule,
    FoldersModule,
    ElementsModule,
  ],
  providers: [
    HttpClientModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FourOOneInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
