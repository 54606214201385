import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FolderListComponent } from './folder-list/folder-list.component';
import { GoogleDriveService } from '../services/google-drive.service';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [FolderListComponent],
  imports: [
    SharedModule
  ],
  providers: [GoogleDriveService]
})
export class FoldersModule { }
