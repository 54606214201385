import { SheetContent } from "./../models/sheet-content.class";
import { GoogleSpreadsheetsService } from "./google-spreadsheets.service";
import { Injectable } from "@angular/core";
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from "@angular/router";
import { Observable, of, EMPTY } from "rxjs";
import { mergeMap, take } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class FormationResolverService {
  constructor(private googleSpreadsheetsService: GoogleSpreadsheetsService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<SheetContent> | Observable<never> {
    const projectId = route.paramMap.get("projetId");

    return this.googleSpreadsheetsService
      .getContent(projectId, "Fiches de formations", "A2", "Z100", false)
      .pipe(
        take(1),
        mergeMap((data: SheetContent) => {
          return of(data);
          // TODO check if project, formation exist
        })
      );
  }
}
