import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { SheetContent } from "src/app/models/sheet-content.class";
import { ActivatedRoute } from '@angular/router';
import { ElementService } from 'src/app/services/element.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: "app-add-element",
  templateUrl: "./add-element.component.html",
  styleUrls: ["./add-element.component.scss"]
})
export class AddElementComponent implements OnInit {
  @Input() elementsSheetContent: SheetContent;
  @Output() closeModalEmitter = new EventEmitter<boolean>();

  public categoryList: Array<string> = [];
  public categorySelected: string;
  public state: string;
  public elementDescription: string;
  public sequenceNumber: number;

  private folderId: any;
  private projectId: any;
  private formationId: any;
  private formationsInformations: any[];
  private formations: any;
  private formation: any;


  constructor(private route: ActivatedRoute, private elementService: ElementService, private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.folderId = params.get("dossierId");
      this.projectId = params.get("projetId");
      this.formationId = params.get("formationId");

      this.formationsInformations = this.elementService.getFormationsInformations(this.route, this.formationId);
      this.formations = this.formationsInformations[0];
      this.formation = this.formationsInformations[1];
    });
    this.sequenceNumber = 1;
    this.getCategoryList();
  }

  createNewElement() {
    this.elementService.createNewElement(
      this.projectId,
      this.formation[0],
      this.elementsSheetContent,
      this.elementDescription,
      this.categorySelected,
      this.formation[2],
      this.formation[1],
      this.state,
      this.sequenceNumber,
      () => {
        this.closeModalEmitter.emit(true);
      }
    );
  }

  getCategoryList() {
    this.elementsSheetContent.values.forEach(element => {
      if (this.categoryList.indexOf(element[3]) === -1) {
        this.categoryList.push(element[3]);
      }
    });
  }

  closeModal() {
    this.closeModalEmitter.emit(true);
  }
}
