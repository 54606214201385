import { ProjectService } from "./../services/project.service";
import { HeaderComponent } from "./../shared/header/header.component";
import { SharedModule } from "./../shared/shared.module";
import { GoogleDriveService } from "./../services/google-drive.service";
import { AddProjectComponent } from "./add-project/add-project.component";
import { ProjectListComponent } from "./project-list/project-list.component";
import { NgModule } from "@angular/core";
import { GoogleSpreadsheetsService } from "../services/google-spreadsheets.service";
import { ProjectComponent } from "./project/project.component";
import { RouterModule } from "@angular/router";
import { ProjectsFormateurPipe } from './projects-formateur.pipe';

@NgModule({
  declarations: [ProjectListComponent, AddProjectComponent, ProjectComponent, ProjectsFormateurPipe],
  imports: [SharedModule, RouterModule],
  providers: [GoogleDriveService, GoogleSpreadsheetsService, ProjectService]
})
export class ProjectsModule {}
