import { GoogleSpreadsheetsService } from "./google-spreadsheets.service";
import { SheetContent } from "./../models/sheet-content.class";
import { Injectable } from "@angular/core";
import jsPDF from "jspdf";
import { environment } from "src/environments/environment";
import { MailSenderService } from "./mail-sender.service";

@Injectable({
  providedIn: "root",
})
export class ElementService {
  constructor(
    private googleSpreadsheetsService: GoogleSpreadsheetsService,
    private mailSenderService: MailSenderService
  ) {}

  generatePdf(formation: Array<string>, elements: SheetContent) {
    const doc: jsPDF = new jsPDF();
    const numberOfElementsPerPage = 6;

    let yPositionOffset = 0;

    doc.setFontSize(20);
    doc.text(5, 10, doc.splitTextToSize(formation[3], 180)[0]);
    doc.setFontSize(15);
    doc.text(5, 20, "Code tarifaire : " + formation[1]);
    doc.text(5, 28, "Version : " + formation[0]);
    doc.setFontSize(12);

    elements.values.forEach((element: Array<string>) => {
      // TODO variabiliser
      // Start drawing rectangles at Y = 32, next rectangle 44 units away
      doc.rect(5, 35 + yPositionOffset * 44, 200, 36);

      doc.text(10, 44 * yPositionOffset + 40, "Catégorie : " + element[3]);
      doc.text(
        150,
        44 * yPositionOffset + 40,
        "Etat : " + environment.availableStates[element[6]]
      );
      doc.text(10, 44 * yPositionOffset + 6 + 40, "Description : ");
      doc.setFontSize(8);
      doc.text(
        10,
        44 * yPositionOffset + 10 + 40,
        doc.splitTextToSize(element[5], 180).slice(0, 6)
      );
      doc.setFontSize(12);

      yPositionOffset += 1;

      if (yPositionOffset === numberOfElementsPerPage) {
        doc.addPage();
        yPositionOffset = 0;
      }
    });

    return doc;
  }

  downloadPdf(doc: jsPDF, filename: string) {
    doc.save(filename + ".pdf");
  }

  emailPdf(
    doc: jsPDF,
    filename: string,
    subject: string,
    message: string,
    senderEmail: string,
    recipientEmail: string,
    callback: any //function
  ) {
    const mail = [
      "MIME-Version: 1.0\r\n",
      `From: ${senderEmail}\r\n`,
      `To: ${recipientEmail}\r\n`,
      `Cc: ${senderEmail}\r\n`,
      'Content-Type: multipart/mixed; boundary="mail_formation"\r\n',
      `Subject: ${
        "=?utf-8?B?" + btoa(unescape(encodeURIComponent(subject))) + "?="
      }\r\n\r\n`,

      "--mail_formation\r\n",
      'Content-Type: text/plain; charset="UTF-8"\r\n',
      "Content-Transfer-Encoding: 7bit\r\n\r\n",

      message + "\r\n\r\n",

      "--mail_formation\r\n",
      "Content-Type: application/pdf\r\n",
      "Content-Transfer-Encoding: base64\r\n",
      `Content-Disposition: attachment; filename="elements-de-formations.pdf"\r\n\r\n`,

      doc.output("dataurlstring").split("base64,")[1],
      "\r\n\r\n",

      "--mail_formation--",
    ].join("");

    this.mailSenderService.sendMessage(mail).subscribe(callback);
  }

  deleteElements(projectId, callback) {
    const emptySheetContent = new SheetContent();
    emptySheetContent.range = "Elements des fiches de formation!A2:Z100";
    emptySheetContent.majorDimension = "ROWS";
    for (let index = 0; index < 99; index++) {
      // 99 because Z100
      emptySheetContent.values[index] = ["", "", "", "", "", "", ""];
    }
    this.googleSpreadsheetsService
      .put(
        projectId,
        "Elements des fiches de formation",
        "A2",
        "Z100", // here
        emptySheetContent
      )
      .subscribe(callback);
  }

  overwriteElements(
    projectId: string,
    elementsSheetContent: SheetContent,
    callback
  ) {
    elementsSheetContent.range = "Elements des fiches de formation!A2:Z100";
    this.googleSpreadsheetsService
      .put(
        projectId,
        "Elements des fiches de formation",
        "A2",
        "Z100",
        elementsSheetContent
      )
      .subscribe(callback);
  }

  transformEtat(elementsSheetContent: SheetContent) {
    elementsSheetContent.values.map((element) => {
      return element[6]
        ? ["NC", "T", "EC", "AF"].indexOf(element[6]) !== -1
          ? element[6]
          : (element[6] = "NC")
        : (element[6] = "NC");
    });
  }

  filterByFormationId(elementsSheetContent: SheetContent, formationId: string) {
    return elementsSheetContent.values.filter((element) => {
      return element[2] ? element[2] === formationId : false;
    });
  }

  getFormationsInformations(route, formationId) {
    const formationsInformations = [];
    route.data.subscribe((data: { formations: SheetContent }) => {
      formationsInformations[0] = data.formations;
      formationsInformations[1] = data.formations.values.filter((formation) => {
        return formation[2] ? formation[2] === formationId : false;
      })[0];
    });
    return formationsInformations;
  }

  createNewElement(
    projectId: string,
    versionFormation: string,
    elementsContent: SheetContent,
    elementDescription: string,
    category: string,
    codeFormation: string,
    rateCode: string,
    state: string,
    sequenceNumber: number,
    callback: any
  ) {
    const newElement = new SheetContent();
    newElement.range =
      "Elements des fiches de formation!" +
      `A${elementsContent.values.length + 2}:Z100`;
    newElement.majorDimension = "ROWS";
    newElement.values = [
      [
        String(versionFormation),
        rateCode,
        codeFormation,
        category,
        String(sequenceNumber),
        elementDescription,
        state,
      ],
    ];

    this.googleSpreadsheetsService
      .put(
        projectId,
        "Elements des fiches de formation",
        `A${elementsContent.values.length + 2}`,
        "Z100",
        newElement
      )
      .subscribe(callback);
  }
}
