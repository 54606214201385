<div class="dimmed-background">
  <div class="modal-container">
    <h3>Nouvel élément d'une formation</h3>
    <p>Catégorie de l'élément</p>
    <input
      list="categoryList"
      [(ngModel)]="categorySelected"
      value="categorySelected"
    />
    <datalist id="categoryList">
      <option *ngFor="let category of categoryList" [value]="category">{{
        category
      }}</option>
    </datalist>
    <p>Numéro séquentiel</p>
    <input
      type="number"
      min="1"
      max="30"
      step="1"
      [(ngModel)]="sequenceNumber"
    />
    <p>Description de l'élément :</p>
    <textarea
      class="addElementDescr"
      [(ngModel)]="elementDescription"
    ></textarea>
    <p>Etat :</p>
    <input
      [(ngModel)]="state"
      title="“T -> Terminé”, “EC -> En cours”, “AF -> A faire”, “NC -> Non concerné”"
      placeholder="“T -> Terminé”, “EC -> En cours”, “AF -> A faire”, “NC -> Non concerné”"
    />
    <div class="buttons">
      <button class="button medium lightgray" (click)="closeModal()">
        Annuler
      </button>
      <button class="button medium accent" (click)="createNewElement()">
        Valider
      </button>
    </div>
  </div>
</div>
