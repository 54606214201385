import { environment } from "./../../environments/environment.prod";
import { HttpHeaders } from "@angular/common/http";
import { GoogleApiService } from "ng-gapi";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UserService } from "./user.service";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MailSenderService {
  private readonly API_URL: string =
    "https://www.googleapis.com/upload/gmail/v1";

  constructor(
    private httpClient: HttpClient,
    private userService: UserService
  ) {}

  sendMessage(email: string) {
    return this.httpClient.post(
      `${this.API_URL}/users/me/messages/send?uploadType=multipart`,
      email,
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.userService.getToken()}`,
          "Content-Type": "message/rfc822",
          "Content-Length": `${JSON.stringify(email).length}`,
        }),
      }
    );
  }
}
