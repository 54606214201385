import { ElementService } from "./../../services/element.service";
import { SheetContent } from "src/app/models/sheet-content.class";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ThrowStmt } from "@angular/compiler";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-element",
  templateUrl: "./element.component.html",
  styleUrls: ["./element.component.scss"]
})
export class ElementComponent implements OnInit {
  @Input() element: Array<string>;
  @Input() canEditAndDelete: boolean;
  @Output() update = new EventEmitter<any>(); // emit a function
  @Output() delete = new EventEmitter<boolean>(); // emit a function

  public availableStates = environment.availableStates;

  public editMode = false;

  constructor(private elementService: ElementService) {}

  ngOnInit(): void {}

  toggleEdit() {
    this.editMode = !this.editMode;
    this.transformEtat();
  }

  cancelEdit() {
    this.editMode = false;
    this.transformEtat();
  }

  transformEtat() {
    this.element[6] = this.element[6]
      ? ["NC", "T", "EC", "AF"].indexOf(this.element[6]) !== -1
        ? this.element[6]
        : (this.element[6] = "NC")
      : (this.element[6] = "NC");
  }

  updateElement() {
    this.update.emit(() => {
      this.toggleEdit();
    });
  }

  deleteElement() {
    this.delete.emit(true);
  }
}
