import { RouterModule } from "@angular/router";
import { SharedModule } from "./../shared/shared.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ElementListComponent } from "./element-list/element-list.component";
import { ElementComponent } from "./element/element.component";
import { ElementFormationPipe } from "./element-formation.pipe";
import { AddElementComponent } from "./add-element/add-element.component";
import { SendElementComponent } from "./send-element/send-element.component";

@NgModule({
  declarations: [
    ElementListComponent,
    ElementComponent,
    ElementFormationPipe,
    AddElementComponent,
    SendElementComponent,
  ],
  imports: [SharedModule, RouterModule],
})
export class ElementsModule {}
