<app-header containerTitle="Projets de formation"></app-header>
<div class="main-container">
  <div class="content" [class]="loading ? 'loading' : ''">
    <div class="two-grid">
      <app-project
        *ngFor="let project of projects | projectsFormateur: canViewAll"
        [project]="project"
        [selectedFolder]="selectedFolder"
        (refresh)="getContentOfSelectedFolder(selectedFolder, true)"
      ></app-project>
      <button class="addProjectButton" *ngIf="canAdd" (click)="openAddModal()">
        +
      </button>
    </div>
    <app-add-project
      *ngIf="showModal"
      (closeModalEmitter)="hideModal($event)"
    ></app-add-project>
    <div class="secondary-container">
      <i
        >Les nouveaux fichiers peuvent mettre du temps avant d'apparaître sur
        cette page. Il faut parfois attendre quelques minutes et raffraîchir la
        page pour qu'ils apparaîssent.</i
      >
    </div>
  </div>
</div>
