<div
  [title]="
    project.content.values
      ? project.content.values[1]
        ? project.content.values[1][2]
          ? project.content.values[1][2]
          : 'Pas de description'
        : 'Pas de description'
      : 'Pas de description'
  "
>
  <div class="flex">
    <h2
      (click)="setSelectedProject()"
      [routerLink]="[
        '/dossiers',
        selectedFolder.id,
        'projets',
        project.metadata.id
      ]"
    >
      {{
        project.content.values
          ? project.content.values[1]
            ? project.content.values[1][1]
              ? project.content.values[1][1]
              : "Pas de nom"
            : "Pas de nom"
          : "Pas de nom"
      }}
    </h2>
    <button
      *ngIf="project.metadata.ownedByMe"
      class="button red rounded icon-container"
      (click)="delete(project.metadata.id)"
    >
      <div class="in-icon-container">
        <img class="icon" src="assets/img/close.png" />
      </div>
    </button>
  </div>
  <p>
    {{
      project.content.values
        ? project.content.values[1]
          ? project.content.values[1][2]
            ? project.content.values[1][2]
            : "Pas de description"
          : "Pas de description"
        : "Pas de description"
    }}
  </p>
</div>
