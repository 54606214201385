import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "elementFormation",
  pure: false
})
export class ElementFormationPipe implements PipeTransform {
  transform(
    value: Array<Array<string>>,
    ...args: string[]
  ): Array<Array<string>> {
    return value.filter(element => {
      // args[0] = formationId
      return element[2] ? element[2] === args[0] : false;
    });
  }
}
