import { UserService } from "./../services/user.service";
import { LoginComponent } from "./login.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

@NgModule({
  declarations: [LoginComponent],
  imports: [CommonModule],
  providers: [UserService],
  bootstrap: [LoginComponent]
})
export class LoginModule {}
