import { GoogleFile } from "./../models/google-file.class";
import { SpreadsheetMetadata } from "./../models/sheet-metadata.class";
import { GoogleSpreadsheetsService } from "src/app/services/google-spreadsheets.service";
import { GoogleDriveService } from "./google-drive.service";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { SheetContent } from "../models/sheet-content.class";

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  constructor(
    private googleDriveService: GoogleDriveService,
    private googleSpreadsheetsService: GoogleSpreadsheetsService
  ) {}

  moveSheetToFolder(spreadsheet: SpreadsheetMetadata, callback: any) {
    this.googleDriveService
      .copyFile(
        spreadsheet.properties.title,
        spreadsheet.spreadsheetId,
        (JSON.parse(sessionStorage.getItem("selectedFolder")) as GoogleFile).id
      )
      .subscribe((file: GoogleFile) => {
        this.googleDriveService
          .deleteFile(spreadsheet.spreadsheetId)
          .subscribe(() => {
            setTimeout(callback, 3000);
          });
      });
  }

  createNewSpreadsheet(
    projectName: string,
    projectDescription: string,
    callback: any
  ) {
    this.googleSpreadsheetsService
      .post(
        this.generateNewSpreadsheetFromTemplate(projectName, projectDescription)
      )
      .subscribe((data: SpreadsheetMetadata) => {
        this.moveSheetToFolder(data, callback);
      });
  }

  copyAlreadyExistingSpreadsheet(
    linkToSpreadSheet: string,
    projectName: string,
    projectDescription: string,
    callback: any
  ) {
    const url = new URL(linkToSpreadSheet);
    if (!url.pathname.startsWith("/spreadsheets/d/")) {
      return;
    }
    if (url.pathname.length < 17) {
      return;
    }

    const spreadsheetId = url.pathname.split("/")[3];

    this.googleDriveService
      .copyFile(
        projectName,
        spreadsheetId,
        (JSON.parse(sessionStorage.getItem("selectedFolder")) as GoogleFile).id
      )
      .subscribe((file: GoogleFile) => {
        const newProject = new SheetContent();
        newProject.range = "Meta-Donnees!" + `A2:Z100`;
        newProject.majorDimension = "ROWS";
        newProject.values = [["1.0", projectName, projectDescription]];
        this.googleSpreadsheetsService
          .put(file.id, "Meta-Donnees", `A2`, "Z100", newProject)
          .subscribe(() => {
            setTimeout(callback, 3000);
          });
      });
  }

  generateNewSpreadsheetFromTemplate(
    projectName: string,
    projectDescription: string
  ) {
    const newSpreadsheet = environment.templateSpreadsheetCreation;
    newSpreadsheet.properties.title = projectName;
    newSpreadsheet.sheets[0].data.rowData[1].values[1].userEnteredValue.stringValue = projectName;
    newSpreadsheet.sheets[0].data.rowData[1].values[2].userEnteredValue.stringValue = projectDescription;

    return newSpreadsheet;
  }
}
