<div class="header">
  <div class="logo-container">
    <img class="logo" src="assets/img/sheets-2.png" />
  </div>
  <div class="title">
    <h1>{{ containerTitle }}</h1>
  </div>
  <div class="login-info">
    <div class="infos">
      <div class="identifiant">{{ this.getCurrentUserName() }}</div>
      <div class="role">({{ this.getRole() }})</div>
    </div>
    <button class="button white rounded icon-container" (click)="signOut()">
      <div class="in-icon-container">
        <img class="icon" src="assets/img/off.png" />
      </div>
    </button>
  </div>
</div>
