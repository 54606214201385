export class SheetContent {
  public range: string;
  public majorDimension: string;
  public values: Array<Array<string>>;

  public constructor() {
    this.values = [];
    this.range = "";
    this.majorDimension = "";
  }
}
