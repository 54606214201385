import { FormationResolverService } from "./services/formation-resolver.service";
import { ElementListComponent } from "./elements/element-list/element-list.component";
import { AuthGuard } from "./login/auth.guard";
import { LoginComponent } from "./login/login.component";
import { ProjectListComponent } from "./projects/project-list/project-list.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FormationListComponent } from "./formations/formation-list/formation-list.component";
import { FolderListComponent } from "./folders/folder-list/folder-list.component";

const routes: Routes = [
  {
    path: "dossiers/:dossierId/projets",
    component: ProjectListComponent,
    data: { authorizedRoles: ["responsable", "formateur"] },
    canActivate: [AuthGuard]
  },
  {
    path: "dossiers/:dossierId/projets/:projetId",
    component: FormationListComponent,
    data: { authorizedRoles: ["responsable", "formateur"] },
    canActivate: [AuthGuard]
  },
  {
    path: "dossiers/:dossierId/projets/:projetId/formations/:formationId",
    component: ElementListComponent,
    data: { authorizedRoles: ["responsable", "formateur"] },
    resolve: {
      formations: FormationResolverService
    },
    canActivate: [AuthGuard]
  },
  {
    path: "dossiers",
    component: FolderListComponent,
    data: { authorizedRoles: ["responsable", "formateur"] },
    canActivate: [AuthGuard]
  },
  {
    path: "",
    redirectTo: "dossiers",
    pathMatch: "full",
    data: { authorizedRoles: ["responsable", "formateur"] },
    canActivate: [AuthGuard]
  },
  {
    path: "login",
    component: LoginComponent
  },
  { path: "**", redirectTo: "dossiers", pathMatch: "full" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
