import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { FormationListComponent } from "./formation-list/formation-list.component";
import { FormationComponent } from "./formation/formation.component";
import { AddFormationComponent } from "./add-formation/add-formation.component";
import { SharedModule } from "../shared/shared.module";

@NgModule({
  declarations: [
    FormationListComponent,
    FormationComponent,
    AddFormationComponent
  ],
  imports: [SharedModule, RouterModule]
})
export class FormationsModule {}
