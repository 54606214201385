import { ProjectService } from "./../../services/project.service";
import { GoogleFile } from "./../../models/google-file.class";
import { SpreadsheetMetadata } from "./../../models/sheet-metadata.class";
import { GoogleDriveService } from "./../../services/google-drive.service";
import { GoogleSpreadsheetsService } from "src/app/services/google-spreadsheets.service";
import { Component, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-add-project",
  templateUrl: "./add-project.component.html",
  styleUrls: ["./add-project.component.scss"],
})
export class AddProjectComponent implements OnInit {
  @Output() closeModalEmitter = new EventEmitter<boolean>();
  public projectName: string;
  public projectDescription: string;
  public tab = 0;
  public linkToProjectTemplate = "";

  constructor(private projectService: ProjectService) {}

  ngOnInit(): void {}

  closeModal() {
    this.closeModalEmitter.emit(true);
  }

  createNewSpreadsheet(tab: number) {
    if (tab === 1) {
      this.projectService.createNewSpreadsheet(
        this.projectName,
        this.projectDescription,
        () => {
          this.closeModalEmitter.emit(true);
        }
      );
    } else {
      this.projectService.copyAlreadyExistingSpreadsheet(
        this.linkToProjectTemplate,
        this.projectName,
        this.projectDescription,
        () => {
          this.closeModalEmitter.emit(true);
        }
      );
    }
  }
}
