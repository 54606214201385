import { Injectable, NgZone } from "@angular/core";
import { GoogleAuthService } from "ng-gapi";
import * as _ from "lodash";
import GoogleUser = gapi.auth2.GoogleUser;
import GoogleAuth = gapi.auth2.GoogleAuth;
import { Router } from "@angular/router";
import { GoogleFile } from "../models/google-file.class";

@Injectable({
  providedIn: "root",
})
export class UserService {
  public static readonly SESSION_STORAGE_KEY: string = "accessToken";
  public static readonly USER_KEY: string = "user";
  public static readonly USER_NAME_KEY: string = "username";
  public static readonly USER_EMAIL_KEY: string = "useremail";
  private user: GoogleUser = undefined;
  private role = "";

  constructor(
    private googleAuthService: GoogleAuthService,
    private ngZone: NgZone,
    private router: Router
  ) {}

  public canAddEditDelete(): boolean {
    const selectedProject: GoogleFile = JSON.parse(
      sessionStorage.getItem("selectedProject")
    );
    return (
      this.getRole() === "formateur" && selectedProject.capabilities.canEdit
    );
  }

  public getRole(): string {
    return this.role || sessionStorage.getItem("role");
  }

  public setUser(user: GoogleUser): void {
    sessionStorage.setItem(UserService.USER_KEY, JSON.stringify(user));
    this.user = user;
  }

  public setUserName(username: string): void {
    sessionStorage.setItem(UserService.USER_NAME_KEY, username);
  }

  public setUserEmail(email: string): void {
    sessionStorage.setItem(UserService.USER_EMAIL_KEY, email);
  }

  public getCurrentUserEmail(): string {
    return sessionStorage.getItem(UserService.USER_EMAIL_KEY);
  }

  public getCurrentUserName(): string {
    return sessionStorage.getItem(UserService.USER_NAME_KEY);
  }

  public getToken(): string {
    const token: string = sessionStorage.getItem(
      UserService.SESSION_STORAGE_KEY
    );
    if (!token) {
      throw new Error("no token set, authentication required");
    }
    return sessionStorage.getItem(UserService.SESSION_STORAGE_KEY);
  }

  public signIn(role: string) {
    this.googleAuthService.getAuth().subscribe((auth) => {
      auth.signIn().then(
        (res) => this.signInSuccessHandler(res),
        (err) => this.signInErrorHandler(err)
      );
    });
    this.role = role;
    sessionStorage.setItem("role", role);
  }

  public signOut(): void {
    this.googleAuthService.getAuth().subscribe((auth) => {
      try {
        auth.signOut();
      } catch (e) {
        console.error(e);
      }
      sessionStorage.clear();
      this.router.navigate(["login"]);
    });
  }

  public isUserSignedIn(): boolean {
    return !_.isEmpty(sessionStorage.getItem(UserService.SESSION_STORAGE_KEY));
  }

  private signInSuccessHandler(res: GoogleUser) {
    this.ngZone.run(() => {
      this.setUser(res);
      this.setUserName(res.getBasicProfile().getName());
      this.setUserEmail(res.getBasicProfile().getEmail());
      sessionStorage.setItem(
        UserService.SESSION_STORAGE_KEY,
        res.getAuthResponse().access_token
      );
      const selectedFolder = JSON.parse(
        sessionStorage.getItem("selectedFolder")
      ) as GoogleFile;
      if (selectedFolder == null) {
        this.router.navigate(["dossiers"]);
      } else {
        this.router.navigate(["dossiers", selectedFolder.id, "projets"]);
      }
    });
  }

  private signInErrorHandler(err) {
    console.warn(err);
  }
}
