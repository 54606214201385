<app-header
  [containerTitle]="
    project.values
      ? project.values[1]
        ? project.values[1][1]
          ? project.values[1][1]
          : 'Pas de nom'
        : 'Pas de nom'
      : 'Pas de nom'
  "
></app-header>
<div class="main-container">
  <div class="content">
    <button class="button back float rounded secondary" (click)="back()">
      <img class="back-icon" src="assets/img/left-arrow.png" />
    </button>
    <div class="list">
      <app-formation
        *ngFor="let formation of formations.values"
        [formation]="formation"
        (delete)="deleteFormation(formation)"
        [canDelete]="canAdd"
      >
      </app-formation>
      <button
        class="button rounded accent add-formation-button"
        *ngIf="canAdd"
        (click)="openAddModal()"
      >
        <img class="back-icon" src="assets/img/plus.png" />
      </button>
    </div>
    <app-add-formation
      *ngIf="showModal"
      (closeModalEmitter)="hideModal($event)"
      [formations]="formations"
      [projectId]="projectId"
    ></app-add-formation>
  </div>
</div>
