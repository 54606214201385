import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from './services/user.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class FourOOneInterceptor implements HttpInterceptor {

  constructor(private userService: UserService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap(
        // Succeeds when there is a response; ignore other events
        event => {},
        // Operation failed; error is an HttpErrorResponse
        (error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.userService.signOut();
          }
        }
      )
    );
  }
}
