<app-header
  containerTitle="Dossier des projets plans de formations"
></app-header>
<div class="main-container">
  <div class="content">
    <p>
      Veuillez sélectionner le dossier où se trouve vos feuilles Google
      Spreadsheet de projet.
    </p>
    <p class="note">Le nom du dossier doit contenir 'Projet Formation'.</p>
    <a *ngFor="let folder of folders" (click)="setSelectedFolder(folder)"
      ><div class="folder-container">
        <img class="folder-icon" src="assets/img/folder.png" />{{ folder.name }}
      </div>
      <br
    /></a>
  </div>
</div>
