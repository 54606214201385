import { UserService } from "src/app/services/user.service";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-formation",
  templateUrl: "./formation.component.html",
  styleUrls: ["./formation.component.scss"]
})
export class FormationComponent implements OnInit {
  @Input() formation: any;
  @Input() canDelete: boolean;
  @Output() delete = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  deleteFormation() {
    this.delete.emit(true);
  }
}
