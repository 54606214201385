<div class="dimmed-background">
  <div class="modal-container">
    <h2>Envoyer les éléments de formation par email</h2>
    <p>Destinataire</p>
    <input
      [(ngModel)]="recipient"
      type="email"
      pattern="[^@]+@[^@]+\.[a-zA-Z]{2,}"
      placeholder="adresse@mail.com"
    />
    <p>Mail</p>
    <textarea [(ngModel)]="mailBody"></textarea>
    <div class="buttons">
      <button class="button medium lightgray" (click)="closeModal()">
        Annuler
      </button>
      <button class="button medium accent" (click)="emailFormation()">
        Envoyer
      </button>
    </div>
  </div>
</div>
