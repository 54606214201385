import { UserService } from "./../../services/user.service";
import { GoogleDriveService } from "./../../services/google-drive.service";
import { Component, OnInit } from "@angular/core";
import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { GoogleSpreadsheetsService } from "src/app/services/google-spreadsheets.service";
import { SheetContent } from "src/app/models/sheet-content.class";
import { Project } from "src/app/models/project.class";
import { GoogleFile } from "src/app/models/google-file.class";
import { SpreadsheetMetadata } from "src/app/models/sheet-metadata.class";
import { Router } from "@angular/router";

@Component({
  selector: "app-project-list",
  templateUrl: "./project-list.component.html",
  styleUrls: ["./project-list.component.scss"],
})
export class ProjectListComponent implements OnInit {
  public loading = true;
  public spreadsheets: Array<SpreadsheetMetadata> = [];
  public projects: Array<Project> = [];
  public selectedProject: Project = null;
  public showModal = false;
  public selectedFolder: GoogleFile = JSON.parse(
    sessionStorage.getItem("selectedFolder")
  );
  public canAdd = false;
  public canViewAll = false;

  constructor(
    private googleDriveService: GoogleDriveService,
    private googleSpreadsheetsService: GoogleSpreadsheetsService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.selectedFolder !== null) {
      this.getContentOfSelectedFolder(this.selectedFolder, false);
      this.canAdd = this.userService.getRole() === "formateur";
      this.canViewAll = this.userService.getRole() === "responsable";
    } else {
      this.router.navigate(["dossier"]);
    }
  }

  openAddModal() {
    this.showModal = true;
  }

  setSelectedProject(project: Project): void {
    this.selectedProject = project;
    sessionStorage.setItem("selectedProject", JSON.stringify(project.metadata));
  }

  getContentOfSelectedFolder(folder: GoogleFile, disableCache: boolean): void {
    this.googleDriveService
      .getFolderContent(folder.id, disableCache)
      .subscribe((folderContent) =>
        this.getSpreadsheetFiles(folderContent, disableCache)
      );
  }

  getSpreadsheetFiles(folderContent: any, disableCache: boolean) {
    const files = folderContent.files.filter(
      (file) => file.mimeType === "application/vnd.google-apps.spreadsheet"
    ) as Array<GoogleFile>;

    const requests = [];
    for (const file of files) {
      requests.push(
        this.googleSpreadsheetsService
          .getContent(file.id, "Meta-Donnees", "A1", "C2", disableCache)
          .pipe(
            map((spreadsheetContent) => ({
              metadata: file,
              content: spreadsheetContent,
            }))
          )
      );
    }

    if (requests.length === 0) {
      this.loading = false;
    }

    forkJoin(requests).subscribe((projects: Array<Project>) => {
      this.projects = projects;
      this.loading = false;
    });
  }

  hideModal(refreshList: boolean) {
    if (refreshList) {
      this.getContentOfSelectedFolder(this.selectedFolder, true);
    }
    this.showModal = false;
  }
}
