import { FormationService } from "src/app/services/formation.service";
import { SheetContent } from "./../../models/sheet-content.class";
import { GoogleSpreadsheetsService } from "./../../services/google-spreadsheets.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "src/app/services/user.service";
import { Location } from "@angular/common";

@Component({
  selector: "app-formation-list",
  templateUrl: "./formation-list.component.html",
  styleUrls: ["./formation-list.component.scss"],
})
export class FormationListComponent implements OnInit {
  public projectId: string;
  public formations: SheetContent = new SheetContent();
  public project: SheetContent = new SheetContent();
  public rateCodes: SheetContent = new SheetContent();
  public showModal = false;
  public canAdd = false;

  private folderId: string;

  constructor(
    private route: ActivatedRoute,
    private googleSpreadsheetsService: GoogleSpreadsheetsService,
    private userService: UserService,
    private formationService: FormationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.folderId = params.get("dossierId");
      this.projectId = params.get("projetId");
      this.getFormationsList();
    });
    this.canAdd = this.userService.canAddEditDelete();
  }

  openAddModal() {
    this.showModal = true;
  }

  hideModal(refreshList: boolean) {
    if (refreshList) {
      this.getFormationsList();
    }
    this.showModal = false;
  }

  back(): void {
    this.router.navigate(["dossiers", this.folderId]);
  }

  getFormationsList() {
    this.googleSpreadsheetsService
      .getContent(this.projectId, "Fiches de formations", "A2", "Z100", true)
      .subscribe((data: SheetContent) => {
        if (data.values) {
          this.formations = data;
        } else {
          this.formations = new SheetContent();
        }
      });
    this.googleSpreadsheetsService
      .getContent(this.projectId, "Meta-Donnees", "A1", "C2")
      .subscribe((data: SheetContent) => {
        this.project = data;
      });

    this.googleSpreadsheetsService
      .getContent(this.projectId, "Codes tarifaires", "A2", "Z100")
      .subscribe((data: SheetContent) => {
        this.rateCodes = data;
        if (!this.rateCodes.values) {
          this.rateCodes.values = [];
        }
      });
  }

  deleteFormation(formation) {
    // Delete element from array, recalculates indexes
    this.formations.values.splice(this.formations.values.indexOf(formation), 1);
    this.formationService.deleteFormations(this.projectId, () => {
      this.formationService.overwriteFormations(
        this.projectId,
        this.formations,
        () => {}
      );
    });
  }
}
