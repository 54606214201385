import { GoogleFile } from "./../../models/google-file.class";
import { Project } from "src/app/models/project.class";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { GoogleDriveService } from "src/app/services/google-drive.service";

@Component({
  selector: "app-project",
  templateUrl: "./project.component.html",
  styleUrls: ["./project.component.scss"]
})
export class ProjectComponent {
  @Input() project: Project;
  @Input() selectedFolder: GoogleFile;
  @Output() refresh = new EventEmitter<boolean>();

  constructor(private googleDriveService: GoogleDriveService) {}

  delete(id: string): void {
    this.googleDriveService.deleteFile(id).subscribe(() => {
      this.refresh.emit(true);
    });
  }

  setSelectedProject() {
    sessionStorage.setItem(
      "selectedProject",
      JSON.stringify(this.project.metadata)
    );
  }
}
