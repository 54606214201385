<div class="dimmed-background">
  <div class="modal-container">
    <h2>Nouvelle formation</h2>
    <p>Nom de la formation</p>
    <input class="addFormationInput" [(ngModel)]="formationName" />
    <p>Version de la formation</p>
    <input
      type="number"
      min="1900"
      max="2099"
      step="1"
      [(ngModel)]="formationVersion"
    />
    <p>Code tarifaire</p>
    <input list="rateCodes" [(ngModel)]="rateCodeSelected" />
    <datalist id="rateCodes">
      <option *ngFor="let rateCode of rateCodeList" [value]="rateCode">{{
        rateCode
      }}</option>
    </datalist>
    <p>Durée de la formation (en jour)</p>
    <input
      type="number"
      min="1"
      max="30"
      step="1"
      [(ngModel)]="formationDuration"
    />
    <p>Description de la formation</p>
    <textarea
      class="addFormationDescr"
      [(ngModel)]="formationDescription"
    ></textarea>
    <div class="buttons">
      <button class="button medium lightgray" (click)="closeModal()">
        Annuler
      </button>
      <button class="button medium accent" (click)="createNewFormation()">
        Valider
      </button>
    </div>
  </div>
</div>
