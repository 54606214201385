import { refCount } from "rxjs/operators";
import { publishReplay } from "rxjs/operators";
import { UserService } from "./user.service";
import { SheetContent } from "./../models/sheet-content.class";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GoogleDriveService } from "./google-drive.service";

@Injectable({
  providedIn: "root"
})
export class GoogleSpreadsheetsService {
  private readonly API_URL: string =
    "https://sheets.googleapis.com/v4/spreadsheets";
  private sheetContents$: Map<string, Observable<any>> = new Map<
    string,
    Observable<any>
  >();
  private spreadsheets$: Map<string, Observable<any>> = new Map<
    string,
    Observable<any>
  >();

  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
    private googleDriveService: GoogleDriveService
  ) {}

  public get(spreadsheetId: string, disableCache?: boolean): Observable<any> {
    const request = this.httpClient
      .get(`${this.API_URL}/${spreadsheetId}`, {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.userService.getToken()}`
        })
      })
      .pipe(publishReplay(), refCount());

    if (!this.spreadsheets$.has(spreadsheetId) || disableCache) {
      this.spreadsheets$.set(spreadsheetId, request);
    }

    return this.spreadsheets$.get(spreadsheetId);
  }

  public getContent(
    spreadsheetId: string,
    sheetName: string,
    from: string,
    to: string,
    disableCache?: boolean
  ): Observable<any> {
    const request = this.httpClient
      .get(
        `${this.API_URL}/${spreadsheetId}/values/${sheetName}!${from}:${to}`,
        {
          headers: new HttpHeaders({
            Authorization: `Bearer ${this.userService.getToken()}`
          })
        }
      )
      .pipe(publishReplay(), refCount());

    if (
      !this.sheetContents$.has(spreadsheetId + sheetName + from + to) ||
      disableCache
    ) {
      this.sheetContents$.set(spreadsheetId + sheetName + from + to, request);
    }

    return this.sheetContents$.get(spreadsheetId + sheetName + from + to);
  }

  public batchGetContent(
    spreadsheetId: string,
    sheetNames: Array<string>,
    froms: Array<string>,
    tos: Array<string>
  ): Observable<any> {
    if (
      sheetNames.length !== froms.length ||
      sheetNames.length !== tos.length ||
      froms.length !== tos.length
    ) {
      return null;
    }
    let url = `${this.API_URL}/${spreadsheetId}/values:batchGet/?ranges=`;
    for (let index = 0; index < sheetNames.length; index++) {
      url +=
        `${sheetNames[index]}!${froms[index]}:${tos[index]}` +
        (index !== sheetNames.length - 1 ? "&ranges=" : "");
    }
    return this.httpClient.get(url, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.userService.getToken()}`
      })
    });
  }

  public put(
    spreadsheetId: string,
    sheetName: string,
    from: string,
    to: string,
    values: SheetContent
  ): Observable<any> {
    return this.httpClient.put(
      `${this.API_URL}/${spreadsheetId}/values/${sheetName}!${from}:${to}?valueInputOption=USER_ENTERED`,
      values,
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.userService.getToken()}`
        })
      }
    );
  }

  public post(spreadsheet: any) {
    return this.httpClient.post(this.API_URL, spreadsheet, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.userService.getToken()}`
      })
    });
  }
}
