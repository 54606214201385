import { UserService } from "./../../services/user.service";
import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input() containerTitle: string = "";
  constructor(private userService: UserService) {}

  ngOnInit(): void {}

  public signIn(role: string) {
    this.userService.signIn(role);
  }

  public signOut() {
    this.userService.signOut();
  }

  public getRole(): string {
    return this.userService.getRole();
  }

  public isLoggedIn(): boolean {
    return this.userService.isUserSignedIn();
  }

  public getCurrentUserName(): any {
    return this.userService.getCurrentUserName();
  }
}
