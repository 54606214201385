import { Project } from "src/app/models/project.class";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "projectsFormateur",
  pure: false
})
export class ProjectsFormateurPipe implements PipeTransform {
  transform(value: Array<Project>, ...args: unknown[]): Array<Project> {
    return value.filter(project => {
      return args[0] || project.metadata.ownedByMe;
    });
  }
}
