<div class="dimmed-background">
  <div class="modal-container">
    <h2>Créer un nouveau projet de formation</h2>

    <div>
      <div class="tabs">
        <div
          (click)="tab = 0"
          [class]="tab === 0 ? 'selected checked' : 'unchecked'"
        >
          <img
            class="checked-icon"
            [src]="
              tab === 0 ? 'assets/img/checked.png' : 'assets/img/unchecked.png'
            "
          />À partir d'un projet de formation existant
        </div>
        <div (click)="tab = 1" [class]="tab === 1 ? 'selected ' : 'unchecked'">
          <img
            class="checked-icon"
            [src]="
              tab === 1 ? 'assets/img/checked.png' : 'assets/img/unchecked.png'
            "
          />Nouveau
        </div>
      </div>
      <div *ngIf="tab === 0">
        <div>Lien Google Drive vers le fichier Spreadsheet</div>
        <input
          class="addProjectInput"
          [(ngModel)]="linkToProjectTemplate"
          placeholder="https://docs.google.com/spreadsheets/d/XXXXXXXXXXXXXXXXX"
        />
      </div>
      <p>Nom du projet</p>
      <input class="addProjectInput" [(ngModel)]="projectName" />
      <p>Description du projet</p>
      <textarea
        class="addProjectDescr"
        [(ngModel)]="projectDescription"
      ></textarea>
    </div>
    <div class="buttons">
      <button class="button medium lightgray" (click)="closeModal()">
        Annuler
      </button>
      <button class="button medium accent" (click)="createNewSpreadsheet(tab)">
        Valider
      </button>
    </div>
  </div>
</div>
