import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleDriveService } from 'src/app/services/google-drive.service';
import { GoogleFile } from 'src/app/models/google-file.class';

@Component({
  selector: 'app-folder-list',
  templateUrl: './folder-list.component.html',
  styleUrls: ['./folder-list.component.scss']
})
export class FolderListComponent implements OnInit {
  folders: Array<GoogleFile> = [];

  constructor(private router: Router, private googleDriveService: GoogleDriveService) { }

  ngOnInit(): void {
    const selectedFolder = JSON.parse(sessionStorage.getItem("selectedFolder"));
    if (selectedFolder !== null) {
      this.router.navigate(['dossiers', selectedFolder.id, 'projets']);
    } else {
      this.googleDriveService
        .findFolders("Projet Formation")
        .subscribe(data => {
          this.folders = data.files;
        });
    }
  }

  setSelectedFolder(folder: GoogleFile): void {
    sessionStorage.setItem("selectedFolder", JSON.stringify(folder));
    this.router.navigate(['dossiers', folder.id, 'projets']);
  }
}
