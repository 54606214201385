import { UserService } from "src/app/services/user.service";
import { FormationService } from "src/app/services/formation.service";
import { ElementService } from "./../../services/element.service";
import { SheetContent } from "./../../models/sheet-content.class";
import { Router } from "@angular/router";
import { GoogleSpreadsheetsService } from "./../../services/google-spreadsheets.service";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit, Input, ViewChild } from "@angular/core";
import jsPDF from "jspdf";
import { Project } from "src/app/models/project.class";
import { GoogleDriveService } from "src/app/services/google-drive.service";
import { SpreadsheetMetadata } from "src/app/models/sheet-metadata.class";
import { GoogleFile } from "src/app/models/google-file.class";

// TODO pas mal de trucs à déplacer dans un service
@Component({
  selector: "app-element-list",
  templateUrl: "./element-list.component.html",
  styleUrls: ["./element-list.component.scss"],
})
export class ElementListComponent implements OnInit {
  public elementsSheetContent: SheetContent = new SheetContent();
  public editMode = false;
  public formations: SheetContent = new SheetContent();
  public formation: Array<string> = [];
  public canAdd: boolean;
  public showModal: boolean;
  public showSendModal: boolean;
  public projectFile: GoogleFile;
  public formationId: string;

  private folderId: string;
  private projectId: string;
  private formationsInformations: Array<any>;

  constructor(
    private route: ActivatedRoute,
    private googleSpreadsheetsService: GoogleSpreadsheetsService,
    private googleDriveService: GoogleDriveService,
    private elementService: ElementService,
    private formationService: FormationService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.canAdd = this.userService.canAddEditDelete();
    this.route.paramMap.subscribe((params) => {
      this.folderId = params.get("dossierId");
      this.projectId = params.get("projetId");
      this.formationId = params.get("formationId");

      this.googleDriveService
        .getFile(this.projectId)
        .subscribe((data: GoogleFile) => {
          this.projectFile = data;
          this.projectFile.permissions = this.projectFile.permissions.filter(
            (user) => {
              return user.type === "user";
            }
          );
        });

      this.formationsInformations = this.elementService.getFormationsInformations(
        this.route,
        this.formationId
      );
      this.formations = this.formationsInformations[0];
      this.formation = this.formationsInformations[1];

      this.getElementsSheetContent();
    });
  }

  back(): void {
    this.router.navigate([
      "dossiers",
      this.folderId,
      "projets",
      this.projectId,
    ]);
  }

  getElementsSheetContent() {
    this.googleSpreadsheetsService
      .getContent(
        this.projectId,
        "Elements des fiches de formation",
        "A2",
        "Z100",
        true
      )
      .subscribe((data: SheetContent) => {
        this.elementsSheetContent = data;
        if (!data.values) {
          this.elementsSheetContent.values = [];
        }
        this.elementService.transformEtat(data);
      });
  }

  toggleEdit() {
    this.editMode = !this.editMode;
  }

  downloadFormation() {
    const filteredElementsSheetContent: SheetContent = JSON.parse(
      JSON.stringify(this.elementsSheetContent)
    );
    filteredElementsSheetContent.values = filteredElementsSheetContent.values.filter(
      (element) => {
        return element[2] ? element[2] === this.formationId : false;
      }
    );
    const doc: jsPDF = this.elementService.generatePdf(
      this.formation,
      filteredElementsSheetContent
    );

    this.elementService.downloadPdf(doc, this.formation[3]);
  }

  updateFormations() {
    this.formationService.overwriteFormations(
      this.projectId,
      this.formations,
      () => {
        this.toggleEdit();
      }
    );
  }

  updateElements(callback) {
    this.elementService.overwriteElements(
      this.projectId,
      this.elementsSheetContent,
      callback
    );
  }

  deleteElement(element) {
    // Delete element from array, recalculates indexes
    this.elementsSheetContent.values.splice(
      this.elementsSheetContent.values.indexOf(element),
      1
    );
    this.elementService.deleteElements(this.projectId, () => {
      this.updateElements(() => {});
    });
  }

  openAddModal() {
    this.showModal = true;
  }

  hideModal(refreshList: boolean) {
    if (refreshList) {
      this.getElementsSheetContent();
    }
    this.showModal = false;
  }

  openSendModal() {
    this.showSendModal = true;
  }

  hideSendModal(refreshList: boolean) {
    if (refreshList) {
      this.getElementsSheetContent();
    }
    this.showSendModal = false;
  }
}
