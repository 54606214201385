import { GoogleFile } from "./../models/google-file.class";
import { Observable, ReplaySubject } from "rxjs";
import { GoogleApiService } from "ng-gapi";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UserService } from "./user.service";
import { refCount, publishReplay } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class GoogleDriveService {
  private readonly API_URL: string = "https://www.googleapis.com/drive/v3";
  private fileLists$: Map<string, Observable<any>> = new Map<
    string,
    Observable<any>
  >();

  constructor(
    private httpClient: HttpClient,
    private userService: UserService
  ) {}

  findFolders(name: string): Observable<any> {
    let params = new HttpParams();
    params = params.set("q", `mimeType='application/vnd.google-apps.folder'`);
    params = params.set("q", `name contains '${name}'`);

    return this.httpClient.get(`${this.API_URL}/files`, {
      params,
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.userService.getToken()}`
      })
    });
  }

  getFolderContent(id: string, disableCache?: boolean): Observable<any> {
    let params = new HttpParams();
    params = params.set("q", `'${id}' in parents and trashed = false`);
    params = params.set(
      "fields",
      `files(id,mimeType,trashed,explicitlyTrashed,ownedByMe,capabilities)`
    );
    const request = this.httpClient
      .get(`${this.API_URL}/files`, {
        params,
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.userService.getToken()}`
        })
      })
      .pipe(publishReplay(), refCount());

    if (!this.fileLists$.has(id) || disableCache) {
      this.fileLists$.set(id, request);
    }

    return this.fileLists$.get(id);
  }

  getFile(id: string): Observable<GoogleFile> {
    let params = new HttpParams();
    params = params.set("fields", `*`);

    return this.httpClient.get<GoogleFile>(`${this.API_URL}/files/${id}`, {
      params,
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.userService.getToken()}`
      })
    });
  }

  updateFileMetadata(
    fileId: string,
    metadata: GoogleFile
  ): Observable<GoogleFile> {
    return this.httpClient.patch<GoogleFile>(
      `${this.API_URL}/files/${fileId}`,
      metadata,
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.userService.getToken()}`
        })
      }
    );
  }

  copyFile(fileName: string, fileId: string, folderId: string) {
    return this.httpClient.post<GoogleFile>(
      `${this.API_URL}/files/${fileId}/copy`,
      {
        parents: [folderId],
        name: fileName
      },
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.userService.getToken()}`
        })
      }
    );
  }

  deleteFile(fileId: string): Observable<any> {
    return this.httpClient.delete(`${this.API_URL}/files/${fileId}`, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.userService.getToken()}`
      })
    });
  }
}
