import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { SheetContent } from "src/app/models/sheet-content.class";
import { ElementService } from "src/app/services/element.service";
import { ActivatedRoute } from "@angular/router";
import { UserService } from "src/app/services/user.service";
import jsPDF from "jspdf";
import { GoogleFile } from "src/app/models/google-file.class";

@Component({
  selector: "app-send-element",
  templateUrl: "./send-element.component.html",
  styleUrls: ["./send-element.component.scss"],
})
export class SendElementComponent implements OnInit {
  @Input() elementsSheetContent: SheetContent;
  @Input() formation: Array<string>;
  @Input() formationId: string;
  @Input() projectFile: GoogleFile;
  @Output() closeModalEmitter = new EventEmitter<boolean>();

  public recipient: string;
  public mailBody: string;

  constructor(
    private elementService: ElementService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.mailBody =
      "Bonjour, \n\nCi-joint, les éléments de formation de la formation " +
      this.formation[3] +
      ".\n\nCordialement,\n\n" +
      this.userService.getCurrentUserName() +
      ".";
  }

  emailFormation() {
    const filteredElementsSheetContent: SheetContent = JSON.parse(
      JSON.stringify(this.elementsSheetContent)
    );
    filteredElementsSheetContent.values = filteredElementsSheetContent.values.filter(
      (element) => {
        return element[2] ? element[2] === this.formationId : false;
      }
    );
    const doc: jsPDF = this.elementService.generatePdf(
      this.formation,
      filteredElementsSheetContent
    );

    this.elementService.emailPdf(
      doc,
      this.formation[3],
      "Elements de formations - " + this.formation[3],
      this.mailBody,
      this.userService.getCurrentUserEmail(),
      this.recipient,
      () => {
        this.closeModalEmitter.emit(true);
      }
    );
  }

  closeModal() {
    this.closeModalEmitter.emit(true);
  }
}
