<div class="element-container">
  <div class="infos">
    <div class="categorie-etat-container">
      <div class="categorie">
        <b>Catégorie éléments : </b
        ><span *ngIf="!editMode">{{ element[3] }}</span
        ><input *ngIf="editMode" [(ngModel)]="element[3]" />
      </div>
      <div class="etat">
        <b>Etat : </b
        ><span *ngIf="!editMode">{{ availableStates[element[6]] }}</span
        ><input
          *ngIf="editMode"
          [(ngModel)]="element[6]"
          title="“T -> Terminé”, “EC -> En cours”, “AF -> A faire”, “NC -> Non concerné”"
          placeholder="“T -> Terminé”, “EC -> En cours”, “AF -> A faire”, “NC -> Non concerné”"
        />
      </div>
    </div>
    <div class="description-edit-container">
      <span><b>Description élément : </b></span>
    </div>
    <p *ngIf="!editMode">
      {{ element[5] }}
    </p>
    <textarea *ngIf="editMode" [(ngModel)]="element[5]"></textarea>
  </div>
  <div class="buttons-container">
    <button
      class="button red rounded icon-container"
      *ngIf="canEditAndDelete && !editMode"
      (click)="deleteElement()"
    >
      <div class="in-icon-container">
        <img class="icon" src="assets/img/close.png" />
      </div></button
    ><button
      class="button accent rounded icon-container"
      *ngIf="canEditAndDelete && !editMode"
      (click)="toggleEdit()"
    >
      <div class="in-icon-container">
        <img class="icon" src="assets/img/edit.png" />
      </div></button
    ><button
      class="button medium lightgray"
      *ngIf="editMode"
      (click)="cancelEdit()"
    >
      Annuler
    </button>
    <button
      class="button medium accent"
      *ngIf="editMode"
      (click)="updateElement()"
    >
      Valider
    </button>
  </div>
</div>
